function initializeRotatingWheel() {
    const rotatingWheel = document.getElementById('rotatingWheel');
    if (!rotatingWheel) {
        return;
    }

    let rotation = 0;
    let isScrolling;
    let scrollDirection = 0;
    let lastScrollTop = 0;

    window.addEventListener('scroll', function () {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        scrollDirection = scrollTop > lastScrollTop ? 1 : -1;
        lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;

        window.clearTimeout(isScrolling);

        isScrolling = setTimeout(function () {
            scrollDirection = 0;
        }, 100);
    });

    function rotateWheel() {
        rotation += scrollDirection * 4;
        rotatingWheel.style.transform = `rotate(${rotation}deg)`;
        requestAnimationFrame(rotateWheel);
    }

    rotateWheel();
}

initializeRotatingWheel();